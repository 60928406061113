module.exports = {
  sites: {
    port: 5001,
  },
  liveStatus: {
    endpointBaseUrl: 'http://localhost.brightcove.com:4000/',
  },
  breakoutRoomMeetingStatus: {
    endpointBaseUrl: 'https://rest.brooms.api.qa.brightcove.com',
  },
};
