module.exports = {
  sites: {
    port: 4000,
    protocol: 'http',
    hostname: '127.0.0.1.nip.io',
  },
  liveStatus: {
    endpointBaseUrl: 'http://localhost.brightcove.com:4000/',
  },
  breakoutRoomMeetingStatus: {
    endpointBaseUrl: 'https://rest.brooms.api.qa.brightcove.com',
  },
};
