const _ = require('underscore');

function qs (obj) {
  return _.map(obj, function (value, key) {
    return encodeURIComponent(key) + '=' + encodeURIComponent(value);
  }).join('&');
}

class VideoDownloads {
  constructor (options) {
    this._options = options || {};
  }

  getDownloadUrlFromSource (video, sourceUrl) {
    const options = this._options;
    if (!options.downloadProxyUrl || !sourceUrl) {
      return sourceUrl;
    }
    const downloadParams = {
      url: sourceUrl,
      filename: video.name + '.mp4',
    };
    return options.downloadProxyUrl + '?' + qs(downloadParams);
  }

  parseDownloadSources (video) {
    const sources = _.filter(video.sources, function (source) {
      return source.avg_bitrate && source.src;
    });
    if (!sources.length) {
      return video;
    }
    const rateSortedRenditions = _.sortBy(sources, ['avg_bitrate']).reverse();

    const audioOnlyRendition = _.find(sources, function (source) {
      return !source.height;
    });

    const videoRenditions = _.filter(sources, function (source) {
      return source.height;
    });
    const sortedRenditions = _.sortBy(videoRenditions, ['height', 'size']).reverse();

    return {
      filename: video.name + '.mp4',
      default: this.getDownloadUrlFromSource(video, rateSortedRenditions[0].src),
      audioOnly: this.getDownloadUrlFromSource(video, audioOnlyRendition && audioOnlyRendition.src),
      highQuality: this.getDownloadUrlFromSource(video, sortedRenditions[0] && sortedRenditions[0].src),
      mediumQuality: this.getDownloadUrlFromSource(video, sortedRenditions[1] && sortedRenditions[1].src),
      lowQuality: this.getDownloadUrlFromSource(video, sortedRenditions[2] && sortedRenditions[2].src),
    };
  }

  getSources (videoId, callback) {
    const options = this._options;
    const self = this;
    const downloadOptions = options.downloadOptions || {};
    const enableCriteria = downloadOptions.enableCriteria;
    const customFieldName = downloadOptions.customField;
    const customFieldValue = downloadOptions.customFieldValue;

    if (['all', 'custom'].indexOf(enableCriteria) === -1) {
      return callback({});
    }

    if (enableCriteria === 'custom' && (!customFieldName || !customFieldValue)) {
      return callback({});
    }

    const url = 'https://edge.api.brightcove.com/playback/v1/accounts/' + options.accountId + '/videos/' + videoId;
    global.bcGallery.$.ajax({
      url: url,
      dataType: 'json',
      jsonp: false,
      headers: {
        accept: 'application/json;pk=' + options.policyKey,
      },
    })
      .done(function (video) {
        const customFields = video.custom_fields || {};
        if (enableCriteria === 'all' || customFields[customFieldName] === customFieldValue) {
          return callback(self.parseDownloadSources(video));
        }
        return callback({});
      })
      .fail(function () {
        callback({});
      });
  }

}

module.exports = {
  VideoDownloads,
};

