module.exports = {
  sites: {
    port: 80,
    hostname: 'stage10.gallerysites.net',
  },
  liveStatus: {
    endpointBaseUrl: 'https://s3.amazonaws.com/bc-gallery-qa-assets/live-status-stage10',
  },
  breakoutRoomMeetingStatus: {
    endpointBaseUrl: 'https://rest.brooms.api.staging.brightcove.com',
  },
};
