module.exports = {
  sites: {
    port: 80,
    hostname: 'bcvp0rtal.com',
  },
  liveStatus: {
    endpointBaseUrl: 'https://portals.assets.brightcove.com/live-status',
  },
  breakoutRoomMeetingStatus: {
    endpointBaseUrl: 'https://rest.brooms.api.brightcove.com',
  },
};
