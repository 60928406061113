module.exports = {
  sites: {
    port: 80,
    hostname: 'stage4.gallerysites.net',
  },
  liveStatus: {
    endpointBaseUrl: 'https://s3.amazonaws.com/bc-gallery-qa-assets/live-status-stage4',
  },
  breakoutRoomMeetingStatus: {
    endpointBaseUrl: 'https://rest.brooms.api.staging.brightcove.com',
  },
};
