/**
 * This module includes logic to allow page tracking across various sources (google analytics, Eloqua, Marketo).
 * It allows registration of URL hash change listeners as well as manual calls to send updates to all services that a user visited a page.
 * This module is necessary to allow Ajax responsive templates to properly track page views
 */
function AnalyticsEngine (deps) {
  this.dependencies = deps;

  this._eloquaEnabled = !!this.dependencies.eloquaId;
  this._marketoEnabled = !!this.dependencies.marketoId;
  this._gaEnabled = !!this.dependencies.gaId;
  /**
   * the following code initializes the analytics modules which were enabled and set in studio for this published site.
   * This automatically registers a pageview of the current page with all services, and sets the account IDs for future tracking
   */
  //initialize eloqua if ID exists
  if (this._eloquaEnabled) {
    window._elqQ = window._elqQ || [];
    // console.log(typeof _elqQ);
    window._elqQ.push(['elqSetSiteId', this.dependencies.eloquaId]);
    window._elqQ.push(['elqTrackPageView', window.location.href]);
  }

  //initialize Marketo if ID exists
  if (this._marketoEnabled) {
    if (global.Munchkin) {
      global.Munchkin.init(this.dependencies.marketoId);
    }
    else {
      this._marketoEnabled = false;
      console.warn('Marketo Disabled');
    }
  }

  //initialize google analytics
  if (this._gaEnabled) {
    if (global.ga) {
      global.ga('create', this.dependencies.gaId);
      global.ga('send', 'pageview');
    }
    else {
      this._gaEnabled = false;
      console.warn('GA analytics disabled');
    }
  }
  /**
   * Manually track a page in all enabled analytics systems. This funciton is useful to track video and page views on ajax templates
   * @param pageUrl - String URL of page the user has visited and we want to have tracked
   */
  this.manualTrackPageView = function (pageUrl) {
    //track the page in google analytics
    if (this._gaEnabled) {
      if (global.ga) {
        global.ga('send', 'pageview', pageUrl);
      }
      else {
        this._gaEnabled = false;
        console.warn('GA analytics disabled');
      }
    }
    // Track the page view in Eloqua
    if (this._eloquaEnabled) {
      window._elqQ = window._elqQ || [];
      window._elqQ.push(['elqTrackPageView', pageUrl]);
    }
    // Track the page view in marketo
    if (this._marketoEnabled) {
      if (global.Munchkin) {
        global.Munchkin.munchkinFunction('visitWebPage', {
          url: pageUrl,
        });
      }
      else {
        this._marketoEnabled = false;
        console.warn('Marketo Disabled');
      }
    }
  };
}

module.exports = {
  AnalyticsEngine,
};
